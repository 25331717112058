import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from './API_CONSTANTS';

// Create an Axios instance with base configurations
const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 20000, // Timeout of 20 seconds
  headers: {
    'Authorization': `Bearer ${Cookies.get("token")}`,
  },
});

// Helper function to set headers dynamically
const setHeaders = (headers = {}, isFormData = false) => ({
  'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
  ...headers,
});

// Function to handle GET requests
export const get = async (url, params, config = {}) => {
  try {
    const response = await axiosInstance.get(url, {
      params,
      headers: setHeaders(config.headers),
      ...config,
    });
    return response.data;
  } catch (error) {
    console.error('Error in GET request:', error);
    throw error;
  }
};

// Function to handle POST requests
export const post = async (url, data, config = {}) => {
  try {
    const isFormData = data instanceof FormData;
    const response = await axiosInstance.post(url, data, {
      headers: setHeaders(config.headers, isFormData),
      ...config,
    });
    return response.data;
  } catch (error) {
    console.error('Error in POST request:', error);
    throw error;
  }
};

// Function to handle PUT requests
export const put = async (url, data, config = {}) => {
  try {
    const isFormData = data instanceof FormData;
    const response = await axiosInstance.put(url, data, {
      headers: setHeaders(config.headers, isFormData),
      ...config,
    });
    return response.data;
  } catch (error) {
    console.error('Error in PUT request:', error);
    throw error;
  }
};

// Function to handle PATCH requests
export const patch = async (url, data, config = {}) => {
  try {
    const isFormData = data instanceof FormData;
    const response = await axiosInstance.patch(url, data, {
      headers: setHeaders(config.headers, isFormData),
      ...config,
    });
    return response.data;
  } catch (error) {
    console.error('Error in PATCH request:', error);
    throw error;
  }
};

// Function to handle DELETE requests
export const deleteRequest = async (url, params, config = {}) => {
  try {
    const response = await axiosInstance.delete(url, {
      data: params,
      headers: setHeaders(config.headers),
      ...config,
    });
    return response.data;
  } catch (error) {
    console.error('Error in DELETE request:', error);
    throw error;
  }
};
