import { TheHousingHubLoading } from "app/components"; // Assuming you have a loading component
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { createContext, useEffect, useReducer } from "react";
import { GET_ADMIN_PROFILE_URL, LOGIN } from "app/networkConfig/API_CONSTANTS";
import { get, post } from "./../networkConfig/Netwok.js";
// Initial authentication state
const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
};

// Reducer function to handle state transitions
const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }
    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }
    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }
    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    default:
      return state;
  }
};

// Create the authentication context
const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {},
});

// AuthProvider component to manage authentication state
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  // Function to handle user login
  const login = async (email, password) => {
    try {
      const data = {
        username: email,
        password: password,
      };
      const response = await post(LOGIN, data);
      const user = response.data;
      const token = response.token;

      // Save the token in cookies
      Cookies.set("userData", JSON.stringify(user)); // Save the user data as a JSON string
      Cookies.set("token", token);

      dispatch({ type: "LOGIN", payload: { user } });
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  // Function to handle user registration
  const register = async (email, username, password) => {
    try {
      const response = await axios.post("/api/auth/register", {
        email,
        username,
        password,
      });
      const { user } = response.data;
      dispatch({ type: "REGISTER", payload: { user } });
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  // Function to handle user logout
  const logout = () => {
    const removeCookies = () => {
      Cookies.remove("userData");
      Cookies.remove("token");
      console.log("Logout function executed");
    };
    removeCookies();
    dispatch({ type: "LOGOUT" }); // Update state to logged out
    navigate("/session/signin"); // Redirect to login page
  };

  // Effect hook to initialize authentication state
  useEffect(() => {
    const initializeAuth = async () => {
      const token = Cookies.get("token");

      if (!token) {
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
        return;
      }

      try {
        const { data } = await get(GET_ADMIN_PROFILE_URL, {
          headers: { Authorization: `Bearer ${token}` },
        });
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: true, user: data.data },
        });
      } catch (error) {
        console.error("Initialization error:", error);
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
      }
    };

    initializeAuth();
  }, []);

  // Show loading spinner while initializing
  if (!state.isInitialized) return <TheHousingHubLoading />;

  // Provide authentication context value to children
  return (
    <AuthContext.Provider
      value={{ ...state, method: "JWT", login, logout, register }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
