import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import TheHousingHubLayout from "./components/TheHousingHubLayout/TheHousingHubLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);
// const Sites = Loadable(lazy(() => import("app/views/master/sites/sites")));
// E-CHART PAGE
const AppEchart = Loadable(
  lazy(() => import("app/views/charts/echarts/AppEchart"))
);
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
const AddPlot = Loadable(lazy(() => import("app/views/master/plots/addPlots")));
const EditPlotForm = Loadable(lazy(() => import("app/views/master/plots/editPlots")));
const AddSites = Loadable(lazy(() => import("app/views/master/sites/addSites")));
const EditSites = Loadable(lazy(() => import("app/views/master/sites/editSites")));
const AddUsers = Loadable(lazy(() => import("app/views/crm/users/addUsers.js")));
const EditUsers = Loadable(lazy(() => import("app/views/crm/users/editUsers.js")));
const ViewUsers = Loadable(lazy(() => import("app/views/crm/users/viewUsers.js")));
const AddBrokers = Loadable(lazy(() => import("app/views/crm/brokers/addBrokers.js")));
const EditBrokers = Loadable(lazy(() => import("app/views/crm/brokers/EditBrokers.js")));
const AddEnquiry = Loadable(lazy(() => import("app/views/crm/enquiry/addEnquiry.js")));
// const EditEnquiry = Loadable(lazy(() => import("app/views/crm/enquiry/editEnquiry.js")));
const AddTransaction = Loadable(
  lazy(() => import("app/views/transaction/addTransaction.js"))
);
const EditTransaction = Loadable(
  lazy(() => import("app/views/transaction/editTransaction.js"))
);

const routes = [
  {
    element: (
      <AuthGuard>
        <TheHousingHubLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: "/dashboard/default",
        element: <Analytics />,
        auth: authRoles.admin,
      },
      // e-chart route
      {
        path: "/charts/echarts",
        element: <AppEchart />,
        auth: authRoles.editor,
      },
      {
        path: "app/views/dashboard/Analytics",
        auth: <authRoles className="guest"></authRoles>,
      },
      // Plots
      {
        path: "/plots/add",
        element: <AddPlot />,
      },
      {
        path: "/plots/edit/:index",
        element: <EditPlotForm />,
      },
      {
        path: "/contacts/add",
        element: <AddUsers />
      },
      {
        path: "/contacts/edit/:index",
        element: <EditUsers />
      },
      {
        path: "/contacts/view/:index",
        element: <ViewUsers />
      },
      {
        path: "/brokers/add",
        element: <AddBrokers />,
      },
      {
        path: "/brokers/edit/:index",
        element: <EditBrokers />,
      },
      {
        path: "projects/add",
        element: <AddSites />,
      },
      {
        path: "/projects/edit/:index",
        element: <EditSites />,
      },
      {
        path: "/leads/add",
        element: <AddEnquiry />,
      },
      {
        path: "/leads/edit/:index",
        element: <EditBrokers />,
      },
      {
        path: "/finance/add",
        element: <AddTransaction />,
      },
      {
        path: "/finance/edit/:index",
        element: <EditTransaction />,
      },
    ],
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
